import { valueAt, isEqual } from "../../../../shared/walkObjectHierarchy"
import types from "../actions/actionTypes"

export default function userListingReducer(state = {}, action) {
  switch (action.type) {
    case types.OPENADDUSERDIALOG:
      return {
        ...state,
        openAddUserDialog: true,
      }
    case types.CLOSEADDUSERDIALOG:
      return {
        ...state,
        openAddUserDialog: false,
      }
    case types.LOADUSERLIST:
      return loadUserList(state, action)
    case types.LOADALLUNASSIGNEDANDMISASSIGNEDUSERS:
      return loadAllUnassignedAndMisassignedUsers(state, action)
    case types.TOGGLEDESCENDANTS:
      return toggleDescendants(state, action)
    case types.TOGGLEHIDDEN:
      return toggleHidden(state, action)
    case types.TOGGLEDELETED:
      return toggleDeleted(state, action)
    case types.UPDATEEXPIRESAT:
      return updateExpiresAt(state, action)
    case types.REMOVECANDIDATEUSER:
      return removeCandidateUser(state, action)
    case types.LOADREMAININGLICENSES:
      return {
        ...state,
        remainingLicenses: action.remaining,
      }
    default:
      return state
  }
}

function loadUserList(state, action) {
  const byId = action.users.reduce((acc, user) => {
    // users and candidate_users can have identical ids (sql)
    // add prefix before the number
    if (user.isCandidateUser) {
      acc["__candidate__" + user.id] = {
        ...user,
        stateId: "__candidate__" + user.id,
      }
    } else {
      acc["__user__" + user.id] = {
        ...user,
        stateId: "__user__" + user.id,
      }
    }
    return acc
  }, {})
  const oldById = valueAt(state, ["userList", "byId"]) || {}
  if (isEqual(oldById, byId)) {
    return state
  }
  return {
    ...state,
    userList: { byId },
  }
}

function loadAllUnassignedAndMisassignedUsers(state, action) {
  const newState1 = loadAllMisassignedUsers(state, action)
  const newState2 = loadAllUnassignedUsers(newState1, action)
  if (newState2 === state) {
    return state
  } else {
    return newState2
  }
}

function loadAllMisassignedUsers(state, action) {
  const byId = action.misassignedUsers.reduce((acc, misassignedUser) => {
    acc["__user__" + misassignedUser.id] = {
      ...misassignedUser,
      tableName: misassignedUser.username,
      stateId: "__user__" + misassignedUser.id, // keep inline to normal users
    }
    return acc
  }, {})
  const oldById = valueAt(state, ["misassignedUsers", "byId"]) || {}
  if (isEqual(oldById, byId)) {
    return state
  }
  return {
    ...state,
    misassignedUsers: { byId },
  }
}

function loadAllUnassignedUsers(state, action) {
  const byId = action.unassignedUsers.reduce((acc, unassignedUser) => {
    acc["__user__" + unassignedUser.id] = {
      ...unassignedUser,
      tableName: unassignedUser.username,
      stateId: "__user__" + unassignedUser.id, // keep inline to normal users
    }
    return acc
  }, {})
  const oldById = valueAt(state, ["unassignedUsers", "byId"]) || {}
  if (isEqual(oldById, byId)) {
    return state
  }
  return {
    ...state,
    unassignedUsers: { byId },
  }
}

function toggleDescendants(state) {
  const value = valueAt(state, ["showDescendants"]) || false
  return {
    ...state,
    showDescendants: !value,
  }
}

function toggleHidden(state) {
  const value = valueAt(state, ["showHidden"]) || false
  return {
    ...state,
    showHidden: !value,
  }
}

function toggleDeleted(state) {
  const value = valueAt(state, ["showDeleted"]) || false
  return {
    ...state,
    showDeleted: !value,
  }
}

function updateExpiresAt(state, action) {
  const allActiveUsers = valueAt(state, ["allActiveUsers"]) || {}
  const byIdState = valueAt(state, ["allActiveUsers", "byId"]) || {}
  const byId = { ...byIdState }
  byId[action.fields.stateId] = {
    ...byId[action.fields.stateId],
    expiresAt: action.fields.expiresAt,
  }
  return {
    ...state,
    allActiveUsers: {
      ...allActiveUsers,
      byId,
    },
  }
}

function removeCandidateUser(state, action) {
  const allActiveUsers = valueAt(state, ["allActiveUsers"]) || {}
  const byIdState = valueAt(state, ["allActiveUsers", "byId"]) || {}
  const byId = { ...byIdState }
  delete byId[action.candidateId]
  return {
    ...state,
    allActiveUsers: {
      ...allActiveUsers,
      byId,
    },
  }
}
