/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * The user menu, including language picker for now (the latter will be
 * moved to personal settings)
 */

import React from "react"
import { Menu, Dropdown } from "antd"
import {
  UserOutlined,
  UnlockOutlined,
  LockOutlined,
  ToolOutlined,
  KeyOutlined,
  IdcardOutlined,
  GlobalOutlined,
  MobileOutlined,
  QrcodeOutlined,
} from "@ant-design/icons"
import { useIntl } from "react-intl"
import { get } from "../../../apps/shared/lib/requestUtils"
import { EDITORGCHART } from "../../../shared/permissions"
import isFeatureAvailable from "../../../apps/shared/lib/featuresetUtils/isFeatureAvailable"

const UserMenu = ({
  context,
  isAdmin,
  isLoggedIn,
  openLoginDialog,
  logout,
  translations,
  username,
  changeAppModule,
  canChangePassword,
  canViewOwnData,
  changeLanguageHandler,
  langOptions,
  problems,
  downloadProblems,
  sendProblems,
  clearProblems,
}) => {
  const intl = useIntl()
  const [canEditClientOptions, setCanEditClientOptions] = React.useState(false)
  const [mobileNotAvailable, setMobileNotAvailable] = React.useState(false)
  React.useEffect(() => {
    void isFeatureAvailable({
      clientId: context.clientId,
      featureType: "MobileApp",
    }).then(a => setMobileNotAvailable(!a.available))
  }, [context.clientId])
  React.useEffect(() => {
    if (isAdmin) {
      setCanEditClientOptions(true)
    } else if (context) {
      void get("/rest/authorisation/getRootPermissionsFor", {
        clientId: context?.clientId,
      }).then(({ root }) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setCanEditClientOptions(root.includes(EDITORGCHART))
      })
    }
  }, [
    isAdmin,
    username,
    context,
  ]) /* compromise: only username readily available, would prefer ID */
  const logInButton = getLoginButton(openLoginDialog, translations.login)
  const loggedInMenu = getLogoutButtonMenu(
    // TODO: clean up parameter list
    intl,
    isAdmin,
    username,
    logout,
    translations.logout,
    translations.loggedInAs,
    translations.settings,
    translations.changePassword,
    changeAppModule,
    canChangePassword,
    translations.profile,
    mobileNotAvailable,
    canViewOwnData,
    canEditClientOptions,
    changeLanguageHandler,
    langOptions,
    translations.langSelect,
    problems,
    translations.problemReport,
    translations.clearProblemReport,
    downloadProblems,
    sendProblems,
    clearProblems
  )
  return (
    <>
      {isLoggedIn && (
        <Dropdown
          overlay={loggedInMenu}
          trigger={["click"]}
          key="logged-in-menu-dropdown"
        >
          <a
            className="ant-dropdown-link"
            data-e2e-test-id="open-user-menu"
            key="logged-in-menu"
            style={{
              display: "inline-block",
              width: 35,
              height: 25,
              verticalAlign: "middle",
              textAlign: "center",
              position: "relative",
            }}
          >
            <UserOutlined
              style={{
                position: "absolute",
                top: 5,
                left: 10,
              }}
            />
          </a>
        </Dropdown>
      )}
      {!isLoggedIn && logInButton}
    </>
  )
}

function getLoginButton(openLoginDialog, loginStr) {
  return (
    <a onClick={openLoginDialog} data-e2e-test-id="open-login-modal">
      <UnlockOutlined />
      {loginStr}
    </a>
  )
}

function getLogoutButtonMenu(
  intl,
  isAdmin,
  username,
  logout,
  logoutStr,
  loggedInAs,
  settingsStr,
  changePasswordStr,
  changeAppModule,
  canChangePassword,
  profileStr,
  mobileNotAvailable,
  canViewOwnData,
  canManageClient,
  changeLanguageHandler,
  langOptions,
  langSelectorText
) {
  const languageSelections = (langOptions || []).map(lang => {
    return (
      <Menu.Item
        key={lang.value}
        onClick={() => changeLanguageHandler(lang.value)}
        disabled={lang.selected}
      >
        {lang.text}
      </Menu.Item>
    )
  })
  return (
    <Menu key="logoutKey" icon={<UserOutlined />}>
      <Menu.Item key="logged-in-as">
        <UserOutlined />
        &nbsp;{loggedInAs}
      </Menu.Item>
      <Menu.Item
        key="mobile"
        disabled={mobileNotAvailable}
        onClick={() => (window.location.href = "/mobile")}
      >
        <MobileOutlined></MobileOutlined>&nbsp;
        {intl.formatMessage({ id: "mobile-link" })}
      </Menu.Item>
      {canManageClient && (
        <Menu.Item
          key="change-client-settings"
          onClick={() => changeAppModule("/clientfeatureconfiguration")}
        >
          <ToolOutlined />
          &nbsp;
          {intl.formatMessage({
            id: "usermenu.label.configure-client-settings",
          })}
        </Menu.Item>
      )}
      {canViewOwnData && (
        <Menu.Item
          key="change-user-data"
          onClick={() => changeAppModule("/userdata")}
        >
          <IdcardOutlined />
          &nbsp;{profileStr}
        </Menu.Item>
      )}
      {/* DISABLED for now:
        <Menu.Item
          key="change-user-settings"
          onClick={() => changeAppModule("/usersettings")}
        >
          <ToolOutlined />
          &nbsp;{settingsStr}
        </Menu.Item>
      */}
      <Menu.SubMenu
        key="langmenu"
        title={langSelectorText}
        icon={<GlobalOutlined />}
      >
        {languageSelections}
      </Menu.SubMenu>
      {canChangePassword && (
        <Menu.Item
          key="change-user-password"
          onClick={() => changeAppModule("/changepassword")}
        >
          <KeyOutlined />
          &nbsp;{changePasswordStr}
        </Menu.Item>
      )}
      {canChangePassword && ( // TODO: isAdmin()
        <Menu.Item
          key="user-otpsetup"
          onClick={() => changeAppModule("/otpsetup")}
        >
          <QrcodeOutlined />
          &nbsp;{intl.formatMessage({ id: "otpsetup" })}
        </Menu.Item>
      )}
      <Menu.Item key="logout" onClick={logout} data-e2e-test-id="logout">
        <LockOutlined />
        &nbsp;{logoutStr}
      </Menu.Item>
    </Menu>
  )
}

export default UserMenu
