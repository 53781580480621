import type FeatureType from "@mina-works/featuresets/dist/definition/interfaces/FeatureType"

export default async function isFeatureAvailable({
  clientId, featureType
}: {
  clientId?: string
  featureType: FeatureType
}): Promise<FeatureAvailability> {
  const url = `/rest/featuresets/isFeatureAvailable?${new URLSearchParams({
    clientId: clientId ?? '', featuretype: featureType
  }).toString()}`
  let result: FeatureAvailability = {
    available: false,
    quota: 0
  }
  try {
    const res = await fetch(
      url,
      {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json; charset=utf-8'
        },
        method: 'GET',
      }
    )
    const json: FeatureAvailabilityJSON = await res.json()
    result = { ...json, quota: json.quota === null ? Number.POSITIVE_INFINITY : json.quota }
  } catch (e) {
    console.error('An error occurred while getting the availability info:', e)
  }
  return result
}

interface FeatureAvailabilityJSON {
  available: boolean
  quota: number | null
}
export interface FeatureAvailability {
  available: boolean
  quota: number
}