/* eslint-disable max-len */
const messages: Record<string, Record<string, string>> = {
  de: {
    closeConversation: 'Konversation beenden/neu starten',
    conversationClosed: 'Konversation neu gestartet',
    'rate-usefulness': 'Nützlichkeit',
    'rate-correctness': 'Korrektheit',
    'rate-remarks': 'Anmerkungen',
    'input-placeholder': "Wie kann ich Ihnen helfen?",
    'empty-reply': 'Leider können wir auf diese Anfrage nicht eingehen.',
    failed: 'Bei der Beantwortung der Anfrage ist ein Fehler aufgetreten.',
    timeout: 'Die KI braucht zu lange, um die Frage zu beantworten.',
    'ticket-sent': "Ihre Anfrage wurde erfolgreich gespeichert",
    'ticket-failed': "Leider konnte Ihre Anfrage nicht gespeichert werden",
    'support-btn': 'Chat',
    'support-h': 'Experimenteller Support-Bot',
    submit: 'Abschicken',
    cancel: 'Abbrechen',
    'chat-privacy-h': "Hinweise zur Datenverarbeitung",
    'chat-warning': "Bitte beachten Sie: Ihre Eingaben werden, u.a. zusammen mit Ihrem Benutzernamen und dem Zeitpunkt der Interaktion, aufgezeichnet und ausgewertet!",
    'chat-help': 'Dieser Chatbot beantwortet Ihnen Fragen zur Benutzung von mina.works, basierend auf der Bedienungsanleitung.',
    'chat-privacy': 'Ihre Angaben werden mit Hilfe von OpenAI/Claude verarbeitet. Zu diesem Zweck wird der eingegebene Text mit den Herstellern geteilt. Bitte beachten Sie, dass die KI unter Umständen falsche oder unvollständige Antworten geben kann. Die Antworten basieren auf dem mina.works-Handbuch. Es kann mehrere Minuten dauern, bis eine Antwort zur Verfügung steht.',
  },
  en: {
    closeConversation: 'Close/restart conversation',
    conversationClosed: 'Conversation restarted',
    'rate-usefulness': 'Usefulness',
    'rate-correctness': 'Correctness',
    'rate-remarks': 'Remarks',
    'input-placeholder': "How can I help you?",
    'empty-reply': 'Sorry, but we cannot find a reply to this message.',
    failed: 'An error has occurred while handling your message.',
    timeout: 'Sorry, AI is taking too long to answer.',
    'ticket-sent': "Your request was sent",
    'ticket-failed': "Your request could not be sent",
    'support-btn': 'Chat',
    'support-h': 'Start a conversation',
    submit: 'Submit',
    cancel: 'Cancel',
    'chat-privacy-h': "Privacy and data processing",
    'chat-warning': 'Your input is logged and reviewed, and is associated with your username and a timestamp, among other information!',
    'chat-help': 'This chatbot can answer questions regarding mina.works based on the mina.works manual. Please note that this is not a real dialogue – every question is answered in isolation.',
    'chat-privacy': 'Information shared with mina.works will be submitted to OpenAI for processing!',
  }

}

export default messages