import types from "./actionTypes"
import { fetchREST } from "../../../../shared/rest.js"
import { encodeURIComponentsForActions } from "../../../../shared/uriUtils"
import {
  showGlobalMessage,
  convertBackendError,
} from "../../../globalMessage/controller"

export function toggleDescendants() {
  return { type: types.TOGGLEDESCENDANTS }
}

export function toggleHidden() {
  return { type: types.TOGGLEHIDDEN }
}

export function toggleDeleted() {
  return { type: types.TOGGLEDELETED }
}

export function openAddUserDialog() {
  return { type: types.OPENADDUSERDIALOG }
}

export function closeAddUserDialog() {
  return { type: types.CLOSEADDUSERDIALOG }
}

export function loadUserList(users) {
  return { type: types.LOADUSERLIST, users }
}

export function loadUserListAsync(data = {}) {
  return (dispatch, getState) => {
    const state = getState()
    if (
      state.oucontext?.currentClientId !== undefined &&
      state.oucontext?.currentOrgUnitId !== undefined
    ) {
      return fetchREST(
        "/userManager/getUserList?" + encodeURIComponentsForActions(data),
        undefined,
        { state }
      )
        .then(res => res.json())
        .then(json => {
          if (json.error) {
            showGlobalMessage(dispatch, convertBackendError(json))
          } else {
            dispatch(loadUserList(json))
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}

function loadAllUnassignedAndMisassignedUsers({
  misassignedUsers,
  unassignedUsers,
}) {
  return {
    type: types.LOADALLUNASSIGNEDANDMISASSIGNEDUSERS,
    misassignedUsers,
    unassignedUsers,
  }
}

export function loadAllUnassignedAndMisassignedUsersAsync(data = {}) {
  const handleError = (dispatch, json) => {
    if (json.error) {
      showGlobalMessage(dispatch, convertBackendError(json))
      return true
    } else {
      return false
    }
  }
  return (dispatch, getState) => {
    const getJSON = name =>
      fetchREST(
        `/userManager/${name}?${encodeURIComponentsForActions(data)}`,
        undefined,
        { state: getState() }
      ).then(res => res.json())
    return getJSON("getAllMisassignedUsers")
      .then(misassignedUsers => {
        if (handleError(dispatch, misassignedUsers)) {
          return Promise.resolve()
        }
        return getJSON("getAllUnassignedUsers").then(unassignedUsers => {
          if (handleError(dispatch, unassignedUsers)) {
            return Promise.resolve()
          }
          dispatch(
            loadAllUnassignedAndMisassignedUsers({
              misassignedUsers,
              unassignedUsers,
            })
          )
          return Promise.resolve()
        })
      })
      .catch(err => {
        console.log(err)
      })
  }
}

export function getUserHomeOUIDAsync(userId, pureDate) {
  return dispatch =>
    fetchREST(
      "/userManager/getUserHomeOUID?" +
        encodeURIComponentsForActions({ userId, pureDate })
    )
      .then(res => res.json())
      .then(json => {
        if (json.error) {
          showGlobalMessage(dispatch, convertBackendError(json))
          return Promise.resolve()
        }
        return Promise.resolve(json)
      })
      .catch(err => {
        console.log(err)
      })
}

export function addUserAsync(fields) {
  return dispatch => {
    return fetchREST("/userManager/addUser", { fields }, { method: "POST" })
      .then(res => res.json())
      .then(json => {
        if (!json.error) {
          dispatch(closeAddUserDialog())
        }
      })
  }
}

function loadRemainingLicenses(remaining) {
  return { type: types.LOADREMAININGLICENSES, remaining }
}

export function loadRemainingLicensesAsync(pureDate) {
  return (dispatch, getState) =>
    fetchREST(
      "/clientManager/getRemainingLicenses?" +
        encodeURIComponentsForActions({ pureDate }),
      undefined,
      { state: getState() }
    )
      .then(res => res.json())
      .then(json => {
        if (json.error) {
          showGlobalMessage(dispatch, convertBackendError(json))
          return Promise.resolve()
        }
        dispatch(loadRemainingLicenses(json))
        return Promise.resolve()
      })
      .catch(err => {
        console.log(err)
      })
}
